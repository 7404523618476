var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('Overlay',{attrs:{"overlay":_vm.overlay}}),_c('CreateRoles',{attrs:{"StoreObj":_vm.StoreObj,"createRoleDialog":_vm.createRoleDialog},on:{"clicked":_vm.createRoleDialogEmit}}),_c('ActivateInactivateRoleDialog',{attrs:{"StoreObj":_vm.StoreObj,"dialogActivateInactivateRole":_vm.dialogActivateInactivateRole},on:{"clicked":_vm.dialogActivateInactivateRoleEmit}}),_c('v-card',{staticClass:"elevation-0"},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"12","xs":"12","sm":"12","md":"6"}},[_c('v-toolbar',{staticClass:"elevation-0",attrs:{"dense":""}},[_c('div',{staticClass:"PrimaryFontColor"},[_vm._v("Roles")])])],1),_c('v-col',{attrs:{"cols":"12","xs":"12","sm":"12","md":"6"}},[_c('v-toolbar',{staticClass:"elevation-0"},[_c('v-spacer'),_c('v-text-field',{staticClass:"field_height field_label_size fontSize maxWidthLarge mt-6 mr-2",attrs:{"dense":"","outlined":"","placeholder":"Search","prepend-inner-icon":"mdi-magnify"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-btn',{attrs:{"small":"","color":"primary"},on:{"click":function($event){(_vm.createRoleDialog = true), (_vm.StoreObj.action = 'CREATE')}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-plus")]),_vm._v("Create")],1)],1)],1)],1),_c('v-card-text',{staticClass:"py-0"},[_c('v-data-table',{staticClass:"elevation-0",attrs:{"dense":"","search":_vm.search,"headers":_vm.roleHeader,"items":_vm.GetAllRolesList,"no-data-text":_vm.noDataText},scopedSlots:_vm._u([{key:"item.role_name",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"FontSize"},[_vm._v(_vm._s(item.role_name))])]}},{key:"item.role_created_on",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"FontSize"},[_vm._v(" "+_vm._s(new Date(item.role_created_on).toLocaleString("en-In"))+" ")])]}},{key:"item.role_status",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"FontSize",class:item.role_status == 'ACTIVE' ? 'GreenHeader' : 'RedHeader'},[_vm._v(" "+_vm._s(item.role_status)+" ")])]}},{key:"item.Actions",fn:function(ref){
var item = ref.item;
return [(!item.role_type)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","small":"","color":'green'},on:{"click":function($event){return _vm.checkItem(item, 'EDIT')}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""},domProps:{"textContent":_vm._s('mdi-pencil')}})],1)]}}],null,true)},[_c('span',{domProps:{"textContent":_vm._s(("Edit " + (item.role_name)))}})]):_vm._e(),(!item.role_type)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","color":item.role_status == 'ACTIVE' ? 'red' : 'green'},on:{"click":function($event){return _vm.checkItem(item, 'ACTION')}}},'v-btn',attrs,false),on),[_c('v-icon',{domProps:{"textContent":_vm._s(item.role_status == 'ACTIVE' ? 'mdi-close' : 'mdi-check')}})],1)]}}],null,true)},[_c('span',{domProps:{"textContent":_vm._s(item.role_status == 'ACTIVE' ? ("Deactivate " + (item.role_name)) : ("Activate " + (item.role_name)))}})]):_vm._e()]}}],null,true)})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }