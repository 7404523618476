<template>
  <div>
    <Overlay :overlay="overlay" />
    <CreateDepartment
      :StoreObj="StoreObj"
      :createDepartmentDialog="createDepartmentDialog"
      @clicked="createDepartmentDialogEmit"
    />
    <ActivateInactivateDepartmentDialog
      :StoreObj="StoreObj"
      :dialogActivateInactivateDepartment="dialogActivateInactivateDepartment"
      @clicked="dialogActivateInactivateDepartmentEmit"
    />
    <v-card class="elevation-0">
      <v-row no-gutters>
        <v-col cols="12" xs="12" sm="12" md="6">
          <v-toolbar dense class="elevation-0">
            <div class="PrimaryFontColor">Departments</div>
          </v-toolbar>
        </v-col>
        <v-col cols="12" xs="12" sm="12" md="6">
          <v-toolbar class="elevation-0">
            <v-spacer></v-spacer>
            <v-text-field
              dense
              outlined
              v-model="search"
              class="field_height field_label_size fontSize maxWidthLarge mt-6 mr-2"
              placeholder="Search"
              prepend-inner-icon="mdi-magnify"
            ></v-text-field>
            <v-btn small color="primary" @click="(createDepartmentDialog = true), (StoreObj.action = 'CREATE')"
              ><v-icon small>mdi-plus</v-icon>Create</v-btn
            >
          </v-toolbar>
        </v-col>
      </v-row>
      <v-card-text class="py-0">
        <v-data-table
          dense
          class="elevation-0"
          :search="search"
          :headers="departmentHeader"
          :items="GetAllDepartmentsList"
          :no-data-text="noDataText"
        >
          <template v-slot:[`item.department_name`]="{ item }">
            <div class="FontSize">{{ item.department_name }}</div>
          </template>
          <template v-slot:[`item.department_created_on`]="{ item }">
            <div class="FontSize">
              {{ new Date(item.department_created_on).toLocaleString("en-In") }}
            </div>
          </template>
          <template v-slot:[`item.department_status`]="{ item }">
            <div class="FontSize" :class="item.department_status == 'ACTIVE' ? 'GreenHeader' : 'RedHeader'">
              {{ item.department_status }}
            </div>
          </template>
          <template v-slot:[`item.Actions`]="{ item }">
            <!-- <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn v-bind="attrs" v-on="on" icon small :color="'green'" @click="checkItem(item, 'VIEW')"><v-icon small v-text="'mdi-information'"></v-icon></v-btn>
              </template>
              <span v-text="'View Designation'"></span>
            </v-tooltip> -->
            <v-tooltip bottom v-if="!item.department_type">
              <template v-slot:activator="{ on, attrs }">
                <v-btn v-bind="attrs" v-on="on" icon small :color="'green'" @click="checkItem(item, 'EDIT')"
                  ><v-icon small v-text="'mdi-pencil'"></v-icon
                ></v-btn>
              </template>
              <span v-text="`Edit ${item.department_name}`"></span>
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  icon
                  :color="item.department_status == 'ACTIVE' ? 'red' : 'green'"
                  @click="checkItem(item, 'ACTION')"
                  ><v-icon v-text="item.department_status == 'ACTIVE' ? 'mdi-close' : 'mdi-check'"></v-icon
                ></v-btn>
              </template>
              <span
                v-text="
                  item.department_status == 'ACTIVE'
                    ? `Deactivate ${item.department_name}`
                    : `Activate ${item.department_name}`
                "
              ></span>
            </v-tooltip>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </div>
</template>
<script>
import Overlay from "@/components/Extras/Overlay.vue";
import CreateDepartment from "@/components/Departments/Dialogs/CreateDepartment.vue";
import ActivateInactivateDepartmentDialog from "@/components/Departments/Dialogs/ActivateInactivateDepartmentDialog.vue";
import { GetAllDepartments } from "@/mixins/GetAllDepartments.js";
export default {
  components: {
    ActivateInactivateDepartmentDialog,
    CreateDepartment,
    Overlay,
  },
  mixins: [GetAllDepartments],
  data: () => ({
    search: "",
    StoreObj: {},
    overlay: false,
    createDepartmentDialog: false,
    dialogActivateInactivateDepartment: false,
    departmentHeader: [
      { text: "Department Name", value: "department_name" },
      { text: "Created On", value: "department_created_on" },
      { text: "Status", value: "department_status" },
      { text: "Actions", value: "Actions" },
    ],
    getAllDepartmentList: [],
    noDataText: "",
  }),
  mounted() {
    this.getAllDepartmentListMethod();
  },
  methods: {
    dialogActivateInactivateDepartmentEmit(Toggle) {
      this.dialogActivateInactivateDepartment = false;
      if (Toggle == 2) {
        this.getAllDepartmentListMethod();
      }
    },
    checkItem(item, ACTION) {
      this.StoreObj = item;
      this.StoreObj.action = ACTION;
      switch (ACTION) {
        case "ACTION":
          this.dialogActivateInactivateDepartment = true;
          break;
        case "EDIT":
          this.createDepartmentDialog = true;
          break;
        case "VIEW":
          this.viewDescriptionDialog = true;
          break;
      }
    },
    createDepartmentDialogEmit(Toggle) {
      this.createDepartmentDialog = false;
      if (Toggle == 2) {
        this.getAllDepartmentListMethod();
      }
    },
  },
};
</script>
