<template>
  <div>
    <Overlay :overlay="overlay" />
    <CreateRoles :StoreObj="StoreObj" :createRoleDialog="createRoleDialog" @clicked="createRoleDialogEmit" />
    <ActivateInactivateRoleDialog
      :StoreObj="StoreObj"
      :dialogActivateInactivateRole="dialogActivateInactivateRole"
      @clicked="dialogActivateInactivateRoleEmit"
    />
    <v-card class="elevation-0">
      <v-row no-gutters>
        <v-col cols="12" xs="12" sm="12" md="6">
          <v-toolbar dense class="elevation-0">
            <div class="PrimaryFontColor">Roles</div>
          </v-toolbar>
        </v-col>
        <v-col cols="12" xs="12" sm="12" md="6">
          <v-toolbar class="elevation-0">
            <v-spacer></v-spacer>
            <v-text-field
              dense
              outlined
              v-model="search"
              class="field_height field_label_size fontSize maxWidthLarge mt-6 mr-2"
              placeholder="Search"
              prepend-inner-icon="mdi-magnify"
            ></v-text-field>
            <v-btn small color="primary" @click="(createRoleDialog = true), (StoreObj.action = 'CREATE')"
              ><v-icon small>mdi-plus</v-icon>Create</v-btn
            >
          </v-toolbar>
        </v-col>
      </v-row>
      <v-card-text class="py-0">
        <v-data-table
          dense
          class="elevation-0"
          :search="search"
          :headers="roleHeader"
          :items="GetAllRolesList"
          :no-data-text="noDataText"
        >
          <template v-slot:[`item.role_name`]="{ item }">
            <div class="FontSize">{{ item.role_name }}</div>
          </template>
          <template v-slot:[`item.role_created_on`]="{ item }">
            <div class="FontSize">
              {{ new Date(item.role_created_on).toLocaleString("en-In") }}
            </div>
          </template>
          <template v-slot:[`item.role_status`]="{ item }">
            <div class="FontSize" :class="item.role_status == 'ACTIVE' ? 'GreenHeader' : 'RedHeader'">
              {{ item.role_status }}
            </div>
          </template>
          <template v-slot:[`item.Actions`]="{ item }">
            <v-tooltip bottom v-if="!item.role_type">
              <template v-slot:activator="{ on, attrs }">
                <v-btn v-bind="attrs" v-on="on" icon small :color="'green'" @click="checkItem(item, 'EDIT')"
                  ><v-icon small v-text="'mdi-pencil'"></v-icon
                ></v-btn>
              </template>
              <span v-text="`Edit ${item.role_name}`"></span>
            </v-tooltip>
            <v-tooltip bottom v-if="!item.role_type">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  icon
                  :color="item.role_status == 'ACTIVE' ? 'red' : 'green'"
                  @click="checkItem(item, 'ACTION')"
                  ><v-icon v-text="item.role_status == 'ACTIVE' ? 'mdi-close' : 'mdi-check'"></v-icon
                ></v-btn>
              </template>
              <span
                v-text="item.role_status == 'ACTIVE' ? `Deactivate ${item.role_name}` : `Activate ${item.role_name}`"
              ></span>
            </v-tooltip>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </div>
</template>
<script>
import Overlay from "@/components/Extras/Overlay.vue";
import CreateRoles from "@/components/Roles/Dialogs/CreateRoles.vue";
import ActivateInactivateRoleDialog from "@/components/Roles/Dialogs/ActivateInactivateRoleDialog.vue";
import { GetAllRoles } from "@/mixins/GetAllRoles.js";
export default {
  components: {
    ActivateInactivateRoleDialog,
    CreateRoles,
    Overlay,
  },
  mixins: [GetAllRoles],
  data: () => ({
    search: "",
    noDataText: "",
    StoreObj: {},
    overlay: false,
    createRoleDialog: false,
    dialogActivateInactivateRole: false,
    roleHeader: [
      { text: "Role Name", value: "role_name" },
      { text: "Created On", value: "role_created_on" },
      { text: "Status", value: "role_status" },
      { text: "Actions", value: "Actions" },
    ],
    getAllRoleList: [],
  }),
  mounted() {
    this.getAllRoleListMethod();
  },
  methods: {
    dialogActivateInactivateRoleEmit(Toggle) {
      this.dialogActivateInactivateRole = false;
      if (Toggle == 2) {
        this.getAllRoleListMethod();
      }
    },
    checkItem(item, ACTION) {
      this.StoreObj = item;
      this.StoreObj.action = ACTION;
      switch (ACTION) {
        case "ACTION":
          this.dialogActivateInactivateRole = true;
          break;
        case "EDIT":
          this.createRoleDialog = true;
          break;
        case "VIEW":
          this.viewDescriptionDialog = true;
          break;
      }
    },
    createRoleDialogEmit(Toggle) {
      this.createRoleDialog = false;
      if (Toggle == 2) {
        this.getAllRoleListMethod();
      }
    },
  },
};
</script>
