import { API } from "aws-amplify";
export const GetAllDepartments = {
  data() {
    return {
      GetAllDepartmentsList: [],
    };
  },
  methods: {
    async getAllDepartmentListMethod() {
      var self = this;
      self.overlay = true
      const path = "listdepartments";
      const myInit = {
        body: {
          organization_id: this.$store.getters.get_current_user_details.organization_id,
        },
      };
      API.post("CHAINPORTAL", path, myInit)
        .then((response) => {
          self.overlay = false
          if (!response.errorType) {
            self.GetAllDepartmentsList = response.data.items;
          } else {
            self.GetAllDepartmentsList = [];
            self.noDataText = response.errorMessage;
          }
        })
        .catch((error) => {
          self.overlay = false
          console.warn(error);
        });
    },
  },
};
