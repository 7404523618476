<template>
  <div>
    <Snackbar :SnackBarComponent="SnackBarComponent" />
    <v-dialog v-model="createRoleDialog" persistent max-width="400px">
      <v-card>
        <v-toolbar color="primary" dark dense class="elevation-0">
          Create Role
          <v-spacer></v-spacer>
          <v-btn icon @click="createRoleDialogEmit((Toggle = 1))"><v-icon>mdi-close</v-icon></v-btn>
        </v-toolbar>
        <v-card-text class="mt-5 pb-0">
          <v-form ref="form">
            <v-text-field dense outlined label="Name" class="field_height field_label_size fontSize" v-model="create_role.role_name" :rules="[(v) => !!v || 'Required']"></v-text-field>
            <v-textarea
              rows="3"
              dense
              outlined
              label="Description"
              class="field_height field_label_size fontSize"
              v-model="create_role.role_description"
              :rules="[(v) => !!v || 'Required']"
            ></v-textarea>
          </v-form>
        </v-card-text>
        <v-card-actions class="pt-0 pr-6 pb-4">
          <v-spacer></v-spacer>
          <v-btn small color="primary" outlined @click="createRoleDialogEmit((Toggle = 1))"><v-icon>mdi-close</v-icon>Cancel</v-btn>
          <v-btn small color="primary" :loading="loading" @click="validateMethod()"><v-icon>mdi-check</v-icon>Confirm</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import Snackbar from "@/components/Extras/Snackbar.vue";
import { API } from "aws-amplify";
export default {
  props: {
    createRoleDialog: Boolean,
    StoreObj: Object,
  },
  components: {
    Snackbar,
  },
  data: () => ({
    Toggle: 0,
    create_role: {
      role_name: "",
      role_description: "",
    },
    loading: false,
    SnackBarComponent: {},
  }),
  watch: {
    createRoleDialog(val) {
      if (val == true) {
        if (this.StoreObj.action == "EDIT") {
          this.create_role = {
            role_name: this.StoreObj.role_name,
            role_description: this.StoreObj.role_description,
          };
        }
      }
    },
  },
  methods: {
    validateMethod() {
      if (this.$refs.form.validate()) {
        this.allCommonMethod(this.StoreObj.action);
      } else {
        this.SnackBarComponent = {
          SnackbarVmodel: true,
          SnackbarColor: "red",
          Top: true,
          SnackbarText: "Fields marked with asterisks (*) are mandatory",
        };
      }
    },
    async allCommonMethod(action) {
      switch (action) {
        case "CREATE":
          var mutationname = "createrole";
          var inputParams = {
            role_name: this.create_role.role_name,
            role_description: this.create_role.role_description,
            organization_id: this.$store.getters.get_current_user_details.organization_id,
            creator_email_id: this.$store.getters.get_user_email,
          };
          break;
        case "EDIT":
          mutationname = "updaterole";
          inputParams = {
            role_id: this.StoreObj.role_id,
            role_name: this.create_role.role_name,
            role_description: this.create_role.role_description,
            organization_id: this.$store.getters.get_current_user_details.organization_id,
            updater_email_id: this.$store.getters.get_user_email,
          };
          break;
      }
      var self = this;
      self.loading = true;
      const path = mutationname;
      const myInit = {
        body: inputParams,
      };
      API.post("CHAINPORTAL", path, myInit)
        .then((response) => {
          if (!response.errorType) {
            this.SnackBarComponent = {
              SnackbarVmodel: true,
              SnackbarColor: "green",
              Top: true,
              SnackbarText: response.Status_Message,
            };
            this.createRoleDialogEmit((this.Toggle = 2));
            this.loading = false;
          } else {
            self.GetCurrentUserList = [];
            self.noDataText = response.errorMessage;
          }
        })
        .catch((error) => {
          this.loading = false;
          this.SnackBarComponent = {
            SnackbarVmodel: true,
            SnackbarColor: "red",
            Top: true,
            SnackbarText: error.errors[0].message,
          };
        });
    },
    createRoleDialogEmit(Toggle) {
      if (this.$refs.form != undefined) {
        this.$refs.form.reset();
      }
      this.$emit("clicked", Toggle);
    },
  },
};
</script>
