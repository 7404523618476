<template>
  <div>
    <Snackbar :SnackBarComponent="SnackBarComponent" />
    <v-dialog v-model="dialogActivateInactivateRole" persistent max-width="400px">
      <v-card>
        <v-toolbar dark dense class="elevation-0" color="primary">
          <div><span v-text="StoreObj.role_status == 'ACTIVE' ? 'Deactivate' : 'Activate'"></span> Role</div>
          <v-spacer></v-spacer>
          <v-btn icon @click="dialogActivateInactivateRoleEmit((Toggle = 1))"><v-icon>mdi-close</v-icon></v-btn>
        </v-toolbar>
        <v-card-text class="text-center mt-5 pb-0">
          <v-icon size="50px" :color="StoreObj.role_status == 'ACTIVE' ? 'red' : 'green'" v-text="StoreObj.role_status == 'ACTIVE' ? 'mdi-close' : 'mdi-check'"></v-icon>
          <div>Are you sure you want to <span v-text="StoreObj.role_status == 'ACTIVE' ? 'Deactivate' : 'Activate'"></span> {{ StoreObj.role_name }} ?</div>
        </v-card-text>
        <v-card-actions class="pb-4">
          <v-spacer></v-spacer>
          <v-btn small class="borderRadius text-capitalize" color="primary" outlined @click="dialogActivateInactivateRoleEmit((Toggle = 1))"><v-icon small class="mr-1">mdi-close</v-icon>Cancel</v-btn>
          <v-btn small class="borderRadius text-capitalize mr-2" color="primary" :loading="loading" @click="activateInactivateMethod()"><v-icon small class="mr-1">mdi-check</v-icon>Confirm</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { API } from "aws-amplify";
import Snackbar from "@/components/Extras/Snackbar.vue";
export default {
  props: {
    dialogActivateInactivateRole: Boolean,
    StoreObj: Object,
  },
  components: {
    Snackbar,
  },
  data: () => ({
    Toggle: 0,
    loading: false,
    SnackBarComponent: {},
  }),
  watch: {},
  methods: {
    async activateInactivateMethod() {
      this.loading = true;
      var self = this;
      const path = "activateordeactivaterole";
      const myInit = {
        body: {
          role_id: self.StoreObj.role_id,
          updater_email_id: this.$store.getters.get_user_email,
          organization_id: self.$store.getters.get_current_user_details.organization_id,
          action: self.StoreObj.role_status == "ACTIVE" ? "DEACTIVATE" : "ACTIVATE",
        },
      };
      API.post("CHAINPORTAL", path, myInit)
        .then((response) => {
          if (!response.errorType) {
            self.SnackBarComponent = {
              SnackbarVmodel: true,
              SnackbarColor: "green",
              Top: true,
              SnackbarText: response.Status_Message,
            };
            self.dialogActivateInactivateRoleEmit((self.Toggle = 2));
            self.loading = false;
          } else {
            self.loading = false;
            self.SnackBarComponent = {
              SnackbarVmodel: true,
              SnackbarColor: "red",
              Top: true,
              SnackbarText: response.errorType,
            };
          }
        })
        .catch((error) => {
          console.warn(error);
          this.loading = false;
          this.SnackBarComponent = {
            SnackbarVmodel: true,
            SnackbarColor: "red",
            Top: true,
            SnackbarText: error.errors[0].message,
          };
        });
    },
    dialogActivateInactivateRoleEmit(Toggle) {
      this.$emit("clicked", Toggle);
    },
  },
};
</script>
